var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://dev-api.instaacard.com';
} else {
    service.API_URL = 'https://dev-api.instaacard.com';
    // service.API_URL = 'http://192.168.29.101:5001';
}

export default service;
